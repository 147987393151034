.canvas {
    padding: 20px;
    height: 70rem;
    width: 100%;
    background-color: white;

    .custom-div {
      border: 1px solid var(--color-text-secondary);
      border-radius: 3px;
      padding: 20px;

      p {
        display: block;
        color: var(--color-text-secondary);
        height: 100%;
        outline: none;
      }
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 3px 3px;

    .key-partners {
      grid-area: 1 / 1 / 3 / 2;
    }

    .key-activities {
      grid-area: 1 / 2 / 2 / 3;
    }

    .key-resources {
      grid-area: 2 / 2 / 3 / 3;
    }

    .value-proposition {
      grid-area: 1 / 3 / 3 / 4;
    }

    .customer-relations {
      grid-area: 1 / 4 / 2 / 5;
    }

    .channels {
      grid-area: 2 / 4 / 3 / 5;
    }

    .customer-segments {
      grid-area: 1 / 5 / 3 / 6;
    }

    .bottom {
      border: none;
      border-radius: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 3px 3px;
      padding-bottom: 3rem;
      grid-area: 3 / 1 / 4 / 6;

      .cost-structure {
        grid-area: 1 / 1 / 2 / 2;
      }

      .revenue-streams {
        grid-area: 1 / 2 / 2 / 3;
      }
      .unique-structure {
        grid-area: 2 / 2 / 2 / 1;
      }
      .market-strategy {
        grid-area: 2 / 2 / 2 / 2;
      }
      additional-notes{
        grid-area: 2 / 2 / 2 / 3;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;

      .bottom {
        display: block;
        width: 100%;
      }
    }

    @media print {
      height: 100%;
    }
  }
