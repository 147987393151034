@font-face {
    font-family: myFirstFont;
    src: url('../public/font/CodecPro-Regular.ttf'); /* IE9 Compat Modes */
}

.custom-line-through {
    text-decoration: line-through;
    text-decoration-color: rgba(255, 255, 255, 0.6); /* Schwarze Linie mit 50% Deckkraft */
}

h1 {
    font-family: myFirstFont, serif;
    font-weight: bold;
    font-size: 25px;
}
h2 {
    font-family: myFirstFont, serif;
    font-weight: bold;
    font-size: 18px;
}
h3 {
    font-family: myFirstFont, serif;
    font-weight: bold;
}
h4 {
    font-family: myFirstFont, serif;
    font-weight: bold;
}

body {
    font-family: myFirstFont, serif;
}
.case-heading {
    line-height: 0.938rem;
  }
  .Typewriter__cursor{
    /* display: none; */
  }

  @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
